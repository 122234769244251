.disabled-button {
  background: #ef233c;
  max-width: 150px;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 24px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  transition: 750ms;
  text-decoration: none;
  cursor: not-allowed;
  text-align: center;
  color: rgba(255, 255, 255, 0.5) !important;
}

.disabled-button:hover {
  background: #ef233c;
  transition: 750ms;
  cursor: not-allowed;
}
