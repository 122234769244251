.agendaSection {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #031722;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.agenda {
  background: none;
  width: 100%;
  max-width: 760px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin: 10px 0px;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.column {
  display: flex;
  flex-direction: column;
}

.agenda-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.agenda-left {
  padding-top: 8px;
  flex-direction: column;
  width: 50%;
}

.agenda-right {
  padding-top: 8px;
  width: 50%;
  flex-direction: column;
}

.agenda-type {
  padding-bottom: 8px;
  text-transform: uppercase;
  opacity: 50%;
}

.left {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.right {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.name {
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.speaker {
  font-size: 18px;
  width: 100%;
  margin-top: 24px;
  opacity: 50%;
  padding: 0px !important;
}

.speakers {
  font-size: 18px;
  width: 100%;
  padding: 0px !important;
}

.topic {
  font-size: 24px;
  display: flex;
  max-width: 760px;
  justify-content: flex-start;
  padding-left: 12px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.company {
  font-size: 14px;
  display: flex;
  justify-content: center;
  opacity: 70%;
}
