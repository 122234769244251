.imageGrid {
  display: flex;
  max-width: 900px;
  grid-gap: 40px;
  align-items: center;
  margin-top: 80px;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.mainLogo {
  margin-top: 40px;
}

.logoIcons {
  max-height: 46px;
}
