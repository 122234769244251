.box {
  background: none;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 24px;
  border: 1px solid #fff;
  text-transform: uppercase;
  transition: 750ms;
  text-decoration: none;
}

.box:hover {
  background: #ef233c;
  transition: 750ms;
  cursor: pointer;
}
