.speakerSection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
  margin-bottom: 80px;
}

.speaker-speaker {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: "#1a1e21";
  cursor: pointer;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.content {
  height: 28px;
  width: 28px;
}

.icon {
  height: 24px;
  width: 24px;
}

.row-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 20px;
  width: 80px;
  margin: auto;
  margin-top: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.company {
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
}
