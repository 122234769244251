* {
  font-family: "Quantico", sans-serif !important;
  color: #fff !important;
  /* font-family: 'Titillium Web', sans-serif; */
}

body {
  background-color: #031927 !important;
}

.font_titillium {
  font-family: "Titillium Web" !important;
}

.dark_blue_bg {
  background-color: #031927 !important;
}

.red_border_color {
  border-color: #ef233c !important;
}

/*




                                  responsive



*/

@media (min-width: 1281px) {
  /* CSS */
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
  .banner_text_wrap h1 {
    font-size: 100px !important;
    line-height: 100px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .imageGrid {
    grid-template-columns: 1fr !important;
    width: 100% !important;
  }

  .speakerSection {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .banner_text_wrap h1 {
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .imageGrid {
    grid-template-columns: 1fr !important;
    width: 100% !important;
  }

  .speakerSection {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 280px) and (max-width: 480px) {
  /* CSS */

  .speakerSection {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .navlog {
    width: 200px !important;
  }

  .imageGrid {
    grid-template-columns: 1fr !important;
    width: 100vw !important;
  }

  .banner_text_wrap h1 {
    font-size: 54px !important;
    line-height: 60px !important;
  }

  .banner_date {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .common_btn {
    font-size: 16px !important;
  }

  .about_text_wrap h1 {
    font-size: 40px !important;
  }

  .responsive-text {
    font-size: 40px !important;
  }
}
